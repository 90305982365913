import { useEffect, type RefObject } from 'react'

export default function useMutationObserver(
  refOrElementSelector: RefObject<HTMLElement> | string,
  callback: MutationCallback,
  options: MutationObserverInit = {
    attributes: true,
    characterData: true,
    childList: true,
    subtree: true,
  }
) {
  useEffect(() => {
    if (typeof window === 'undefined') return
    const element =
      typeof refOrElementSelector === 'string'
        ? document.querySelector(refOrElementSelector)
        : refOrElementSelector.current
    if (element) {
      const observer = new MutationObserver(callback)
      observer.observe(element, options)
      return () => observer.disconnect()
    }
  }, [callback, options])
}
