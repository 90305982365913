import { useState } from 'react'
import type { Entry } from 'contentful'
import RichText from '@/components/ui/rich-text'
import DynamicAction, { type DynamicActionProps } from '@/components/ui/dynamic-action'
import Image from '@/components/ui/image'
import { buttonStyles, type VariantType } from '@/components/ui/cta-link'
import colors from '@/constants/colors'
import { cx } from '@/utils/strings'
import useMutationObserver from '@/utils/useMutationObserver'
import type { TypeComponentCtaLinkSkeleton, TypeComponentHubspotCtaSkeleton, TypeSectionCtaSkeleton } from '@/types/ctf'
import { useLiveUpdates } from '@/utils/live-preview'
import { useEventListener } from 'usehooks-ts'

type SectionCtaEntry = Entry<TypeSectionCtaSkeleton, 'WITHOUT_UNRESOLVABLE_LINKS'>
type ComponentCtaLinkEntry = Entry<TypeComponentCtaLinkSkeleton, 'WITHOUT_UNRESOLVABLE_LINKS'>
type ComponentHubspotCtaEntry = Entry<TypeComponentHubspotCtaSkeleton, 'WITHOUT_UNRESOLVABLE_LINKS'>

type SectionCtaProps = SectionCtaEntry['fields'] & {
  entryId?: string
  className?: string
}

export default function Cta({ entryId, className, ...fields }: SectionCtaProps): JSX.Element {
  const {
    fields: { actions, body, displayStyle, sticky, image, backgroundImage, backgroundColor: _backgroundColor },
  } = useLiveUpdates({ fields, sys: { id: entryId } })
  const backgroundColor = _backgroundColor || colors.primaryBlue

  const [headerHeight, setHeaderHeight] = useState(0)
  const calcHeaderHeight = () => {
    const header = document.querySelector('header')
    if (header) {
      const height = header.offsetHeight
      setHeaderHeight(height)
    }
  }
  useMutationObserver('header', calcHeaderHeight)
  useEventListener('resize', calcHeaderHeight)

  const renderCTA = (
    action: ComponentCtaLinkEntry | ComponentHubspotCtaEntry | undefined,
    btnStyle: VariantType,
    scrollToOffset = 0
  ) => {
    if (!action) return null
    const type = action.sys.contentType.sys.id
    const fields = {
      type,
      ...action.fields,
    } as DynamicActionProps
    return (
      <DynamicAction
        key={action.sys.id}
        {...fields}
        style={sticky ? btnStyle : fields.style}
        backgroundColor={sticky ? colors.white : backgroundColor}
        scrollToOffset={scrollToOffset}
      />
    )
  }

  return (
    <section
      id={sticky ? 'sticky-cta' : undefined}
      className={cx('max-w-8xl mx-auto', sticky ? `sm:sticky z-20 px-0 lg:px-9 w-fit ` : `px-5 lg:px-9`, className)}
      style={sticky && headerHeight ? { top: `${headerHeight + 12}px` } : undefined}
    >
      <div
        className={cx(
          'flex items-center gap-6',
          displayStyle === 'Inline' && 'justify-center content-center flex-col xl:flex-row',
          displayStyle === 'Block' && 'flex-col',
          !sticky && backgroundColor === colors.primaryBlue && 'bg-primaryBlue text-white',
          !sticky && backgroundColor === colors.indigoBlue && 'bg-indigoBlue text-white',
          !sticky && backgroundColor === colors.white && 'bg-white !text-indigoBlue shadow-lg',
          !sticky && backgroundColor === colors.lightGray && 'bg-gray-100 !text-indigoBlue',
          sticky ? 'bg-white sm:bg-transparent' : 'px-5 rounded-4xl py-12',
          'bg-cover bg-center bg-no-repeat'
        )}
        style={backgroundImage ? { backgroundImage: `url(${backgroundImage.fields.file?.url})` } : undefined}
      >
        {!sticky && image && (
          <div className="svg:fill-none [&_svg]:w-[190px] [&_img]:w-[190px] [&_svg]:h-auto [&_img]:h-auto [&_figure]:!m-0">
            <Image image={image} />
          </div>
        )}
        {!sticky && body && (
          <div className="text-center [&_h2]:h6 [&_h2]:m-0 [&_h3]:h6 [&_h3]:m-0 [&_h4]:h6 [&_h4]:m-0 [&_p]:text-2xl [&_p]:m-0 [&_p]:[text-wrap:balance]">
            <RichText body={body} />
          </div>
        )}

        {actions && (
          <div>
            <div
              className={cx(
                'flex flex-col items-center shrink-0 flex-wrap',
                sticky
                  ? `gap-0 sm:gap-4 sm:flex-row sm:rounded-full p-3 sm:shadow-even-around bg-white`
                  : `gap-4 lg:flex-row`
              )}
              data-testid="actions"
            >
              {actions.map((action: any, idx) => (
                <div key={idx}>
                  {!sticky && (
                    <div
                      className={cx(
                        `items-center relative`,
                        idx === 0 &&
                          !sticky &&
                          actions.length > 1 &&
                          `lg:pr-4 lg:after:content-[''] lg:after:block lg:after:right-0 lg:after:top-1 lg:after:bottom-1 lg:after:absolute lg:after:w-[1px]`,
                        (backgroundColor === colors.primaryBlue || backgroundColor === colors.indigoBlue) &&
                          `after:bg-white`,
                        (backgroundColor === colors.white || backgroundColor === colors.lightGray) &&
                          `after:bg-indigoBlue`
                      )}
                    >
                      {renderCTA(action, buttonStyles.btnSolid, 50)}
                    </div>
                  )}

                  {sticky && (
                    <div>
                      <div className="block sm:!hidden">{renderCTA(action, buttonStyles.btnArrow, 100)}</div>
                      <div className={cx(sticky ? `hidden sm:flex` : `flex`, `items-center relative`)}>
                        {renderCTA(action, buttonStyles.btnSolid, 50)}
                      </div>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </section>
  )
}
